function getOrderOnlineLink(title) {
  const linkMap = {
    // "p-prairie-donair-airdrie": "https://prairiedonair.gpr.globalpaymentsinc.ca/menu/?deliveryInstructions=&locationId=34174&orderMethod=3&orderType=1&pickupCurbside=false",
    // "p-prairie-donair-crowfoot": "https://prairiedonair.gpr.globalpaymentsinc.ca/menu/?deliveryInstructions=&locationId=34167&orderMethod=3&orderType=1&pickupCurbside=false",
    // "p-prairie-donair-deer-ridge": "https://prairiedonair.gpr.globalpaymentsinc.ca/menu/?deliveryInstructions=&locationId=34164&orderMethod=3&orderType=1&pickupCurbside=false",
    
    // "p-subexpress-regina-quance":"N/A",
  };
  
  return linkMap[title] || `https://orders.iorders.online/${title}`;
}

// ... rest of the code remains unchanged
const storeDetails = {
  status: "success",
  data: {
    restaurants: [
      //1. Cosmic Pizza & Donair Saskatoon
      {
        profile: {
          id: 124,
          restaurant_name: "Cosmic Pizza & Donair Saskatoon",
          title: "p-cosmic-pizza-donair-saskatoon",
          address: "1164 Baltzan Blvd, Saskatoon, SK S7W 1K4",
          phone: "+1 (306) 986-6633",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '10:30:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '10:30:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '10:30:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '10:30:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          lat: "52.170094859649225",
          lng: "-106.55435191736382",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //2. Cosmic Pizza & Donair Windermere
      {
        profile: {
          id: 124,
          restaurant_name: "Cosmic Pizza & Donair Windermere",
          title: "p-cosmic-pizza-donair-windermere",
          address: "17316 Hiller Rd SW, Edmonton, AB T6W 4H3",
          phone: "+1 (780) 581-1713",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '12:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '00:01:00', closing_time: '02:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1746, day: 'SUNDAY', opening_time: '00:01:00', closing_time: '02:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          lat: "53.42172114111474",
          lng: "-113.61927785339174",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
       //3. Cosmic Pizza & Donair Heritage
       {
        profile: {
          id: 124,
          restaurant_name: "Cosmic Pizza & Donair Heritage",
          title: "p-cosmic-pizza-donair-heritage", 
          address: "347 Heritage Dr SE, Calgary, AB T2H 1M8",
          phone: "+1 (780) 789-0406",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '12:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '10:30:00', closing_time: '24:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          lat: "50.97875718315754",
          lng: "-114.0618985351414",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },

      
    ]
  },
  message: "successful response"
};

export default storeDetails;
